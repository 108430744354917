import { create } from 'zustand';

export interface ActiveState {
  enabled: boolean;
}

type Language = {
  country: string;
  language: string;
};

export type LanguagesSettingsState = {
  defaultLanguage: string;
  languages: Language[];
};

type supportedCountriesState = {
  countries: string[];
};

export type SettingsState = {
  activeState: ActiveState;
  languagesState: LanguagesSettingsState;
  supportedCountriesState: supportedCountriesState;
  setActiveState: (values: ActiveState) => void;
  setLanguagesState: (values: LanguagesSettingsState) => void;
  setSupportedCountriesState: (values: supportedCountriesState) => void;
};

const initialState: SettingsState = {
  activeState: {
    enabled: true,
  },
  languagesState: {
    defaultLanguage: 'fr',
    languages: [],
  },
  supportedCountriesState: {
    countries: [],
  },

  setActiveState: () => null,
  setLanguagesState: () => null,
  setSupportedCountriesState: () => null,
};

export const useSettingsStore = create<SettingsState>((set) => ({
  ...initialState,
  setActiveState: (values: ActiveState) =>
    set(({ activeState }) => ({
      activeState: { ...activeState, ...values },
    })),
  setLanguagesState: (values: LanguagesSettingsState) =>
    set(({ languagesState }) => ({
      languagesState: { ...languagesState, ...values },
    })),
  setSupportedCountriesState: (values: supportedCountriesState) =>
    set(({ supportedCountriesState }) => ({
      supportedCountriesState: { ...supportedCountriesState, ...values },
    })),
}));
