import React from 'react';
import RegisterWithEmail from './RegisterWithEmail';
import RegisterWithPhone from './RegisterWithPhone';
import { getEnv, envEnum } from 'src/utils/env';

const Register = () => {
  return getEnv(envEnum.VERSION) === 'global' ? (
    <RegisterWithEmail />
  ) : (
    <RegisterWithPhone />
  );
};

export default Register;

