import React, { useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import _, { set } from 'lodash';

import { TextInput } from 'src/components/Inputs';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { usePaymentConfirm } from 'src/hooks/queries/useCurrency';
import { CountryPhoneInput } from 'src/components/Inputs';
import CardWrapper from 'src/components/Common/CardWrapper';
import Brand from './Brand';
import { getCountry } from 'src/utils/localStorage';
import { useTranslation } from 'react-i18next';

const formSchema = yup.object().shape({
  phone: yup.string().required('Required'),
  sub_option: yup.string().required('Required'),
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export const SubOptions = () => {
  const { i18n, t } = useTranslation();
  const [formInputs, setFormInputs] = React.useState<any>([]);
  const [selectedId, setSelectedId] = React.useState<string>('');

  const country = getCountry();
  const {
    onNext,
    setTransactionState,
    transactionState: { id, subOptions },
  } = useBuyCurrencyStore();

  const { mutate: confirm, isLoading } = usePaymentConfirm();

  const { register, formState, handleSubmit, control, watch, setValue } =
    useForm({
      resolver: yupResolver(formSchema),
    });
  const watchOption = watch('sub_option');

  const handleSubmitForm = (values: any) => {
    confirm(
      {
        ...values,
        phone_number: values.phone,
        payment_id: id as string,
        payment_option: 'Mobile',
      },
      {
        onSuccess: (data) => {
          setTransactionState({
            instructions: {
              data: _.get(data, 'result.paymentDetails[0].value', ''),
            },
          });
          onNext();
        },
      }
    );
  };
  useEffect(() => {
    if (watchOption) {
      const subOption = subOptions?.find((item) => item.id === watchOption);
      setFormInputs(_.get(subOption, 'formInputs', []));
    }
  }, [watchOption]);

  const handleBrandChange = (id: string) => () => {
    setSelectedId(id);
    setValue('sub_option', id);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <CardWrapper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4' fontWeight='bold' mb={1}>
            {t('MobilePayment')}
          </Typography>
        </Box>
        <Stack spacing={2} mt={4}>
          <Stack spacing={2} mt={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {subOptions?.map((item) => (
                <Brand
                  key={item.id}
                  title={item.name}
                  id={item.id}
                  selectedId={selectedId}
                  onClick={handleBrandChange}
                  image={item.logo}
                />
              ))}
            </Box>

            {formInputs?.map((item: any) => {
              if (item.name == 'phone') {
                return (
                  <CountryPhoneInput
                    country={country as any | ''}
                    label={t('phoneNumber')}
                    control={control}
                    {...register('phone')}
                  />
                );
              }
              if (item.name == 'voucher') {
                return (
                  <TextInput
                    label={t('voucher')}
                    required
                    {...register('voucher')}
                  />
                );
              }
            })}
          </Stack>
        </Stack>
        <Box display='flex' justifyContent='center' mt={2}>
          <LoadingButton
            variant='contained'
            type='submit'
            loading={isLoading}
            fullWidth
          >
            {t('payment')}
          </LoadingButton>
        </Box>
      </CardWrapper>
    </form>
  );
};

export default SubOptions;

