import { useQuery, useMutation } from 'react-query';
import { getSettings } from 'src/apis/settings';
import _ from 'lodash';
import { useSettingsStore } from 'src/stores/settings';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';

export const useMutateGetSettings = () => {
  const { setSessionState } = useBuyCurrencyStore();
  const { setActiveState, setLanguagesState, setSupportedCountriesState } =
    useSettingsStore();
  return useMutation(getSettings, {
    onSuccess: (data) => {
      setActiveState({ enabled: data.enabled });
      setLanguagesState({
        defaultLanguage: _.get(data, 'default_language', 'fr'),
        languages: _.get(data, 'languages', []),
      });
      setSupportedCountriesState({ countries: _.get(data, 'countries', []) });
      setSessionState({ firstPay: true });
    },
  });
};
