import React from 'react';
import { Box, Typography } from '@mui/material';
import CardWrapper from 'src/components/Common/CardWrapper';
import _ from 'lodash';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RoutePath } from 'src/routes/path';
import CircularProgress from '@mui/material/CircularProgress';

export const Instruction = () => {
  const {
    transactionState: { instructions, id },
  } = useBuyCurrencyStore();
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const handleViewPaymentDetails = () => {
    history.push(
      `${RoutePath.TRANSACTION_DETAILS.replace(':id', id as string)}`
    );
  };
  return (
    <Box>
      <CardWrapper>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h4'>{t('instructions')}</Typography>
        </Box>
        <Box mt={2} sx={{ textAlign: 'center' }}>
          <Typography mt={4}>{instructions?.data}</Typography>
          <Box alignItems='center' mt={2}>
            <CircularProgress />
          </Box>
          <Typography variant='caption' mt={4}>
            {t('waitingForPayment')}
          </Typography>
        </Box>
        <Box
          display='flex'
          justifyContent='center'
          mt={4}
          onClick={handleViewPaymentDetails}
        >
          <LoadingButton variant='contained'>
            {t('PaymentDetails')}
          </LoadingButton>
        </Box>
      </CardWrapper>
    </Box>
  );
};

export default Instruction;
