import axios from 'axios';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { APIPath } from 'src/apis/api-path';
import {
  getFee,
  getPairs,
  getTransactionHistories,
  payment,
  confirm,
  preparePayment,
  verifyWalletAddress,
  getPaymentOptions,
  getTransactionDetails,
} from 'src/apis/currency';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { Pagination } from 'src/Types/Common';
import { GetFee } from 'src/Types/Currency';
import { handleError, toastError, toastSuccess } from 'src/utils/toast';
import { getCountry } from 'src/utils/localStorage';
import { t } from 'i18next';

const doGetPairs = async (country: string) => {
  const pairs = await getPairs(country);

  const result = _.mapValues(pairs, (array) =>
    array.reduce((newArray, curr) => {
      const obj = { ...curr };
      // eslint-disable-next-line no-restricted-syntax
      for (const network of curr.networks) {
        const newObj = {
          ...obj,
          id: `${obj.asset_id}-${network}`,
          network,
        };
        // @ts-ignore
        newArray.push(newObj);
      }
      return newArray;
    }, [])
  );

  return {
    pairs: result,
    pairOfStrings: _.keys(pairs) as string[],
  };
};

export const useGetPairs = () => {
  const country = getCountry();
  return useQuery(
    [APIPath.GET_PAIRS_CURRENCY],
    () => doGetPairs(country || 'US'),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useVerifyWalletAddress = () =>
  useMutation(verifyWalletAddress, {
    onSuccess: (res) => {
      if (!res.isValid) {
        toastError({ description: t('incorrectAddress') });
      }
    },
  });

export const useGetFee = (data: GetFee) => {
  const { setTransactionState } = useBuyCurrencyStore();
  const { currency: from_currency, token: to_currency, network } = data;

  return useQuery(
    [APIPath.GET_FEE, { ...data }],
    async () => {
      const res = await getFee(data);
      const {
        transactionState: { currency, spend },
      } = useBuyCurrencyStore.getState();
      const price = _.get(currency, 'price', 0);
      const serviceFee = _.get(res, 'convert_fee.fee', 0);
      const networkFee = _.get(res, 'tx_fee.fee', 0);
      const networkFeeInUSD = _.get(res, 'tx_fee_in_usd.fee', 0);
      const receive =
        (Number(spend) - (serviceFee * Number(spend)) / 100 - networkFee) /
        price;

      setTransactionState({
        networkFee,
        serviceFee,
        receive: spend ? _.round(receive, 8) : '',
        networkFeeInUSD,
      });
      return res;
    },
    {
      enabled: !!(network && from_currency && to_currency),
      refetchOnWindowFocus: false,
    }
  );
};

export const usePreparePayment = () => {
  const { setTransactionState } = useBuyCurrencyStore();
  return useMutation(preparePayment, {
    onSuccess: ({ id }) => {
      setTransactionState({ id, serviceFee: 0, networkFee: 0 });
    },
  });
};

export const usePayment = () => {
  const { resetStore, setTransactionState } = useBuyCurrencyStore();
  return useMutation(payment, {
    onError: (error) => {
      handleError(error);
    },
    onSuccess: (res) => {
      if (res.resultType === 'success') {
        toastSuccess({ description: t('paymentSuccess') });
        resetStore();
      }
      if (res.resultType === 'redirect') {
        toastSuccess({ description: t('paymentSuccess') });
        window.location.replace(res.result.redirectUrl);
      }
      if (res.resultType === 'options') {
        setTransactionState({
          subOptions: res.options,
          serviceFee: 0,
          networkFee: 0,
        });
        toastSuccess({ description: t('plsSelectSubOption') });
      }
    },
  });
};

export const usePaymentConfirm = () => {
  const { resetStore } = useBuyCurrencyStore();

  return useMutation(confirm, {
    onError: (error) => {
      handleError(error);
    },
    onSuccess: (res) => {
      if (res.resultType === 'success') {
        toastSuccess({ description: t('paymentSuccess') });
        resetStore();
      }
      if (res.resultType === 'redirect') {
        resetStore();
        toastSuccess({ description: t('paymentSuccess') });
        setTimeout(() => {
          window.open(res.result.redirectUrl, '_blank');
        }, 1000);
      }
      if (res.resultType === 'info') {
        toastSuccess({ description: t('paymentSuccess') });
      }
    },
  });
};

export const useGetTransactionHistories = (params: Pagination) =>
  useQuery([APIPath.GET_TRANSACTION_HISTORIES, { ...params }], async () => {
    const {
      docs: items,
      pagination: { totalDocs: total },
    } = await getTransactionHistories(params);
    return {
      items,
      total,
    };
  });

export const useGetCurrencies = () =>
  useQuery(['GET_CURRENCIES'], async () => {
    const { data: ethData } = await axios.get(
      'https://api.coingecko.com/api/v3/coins/ethereum'
    );
    const { data: btcData } = await axios.get(
      'https://api.coingecko.com/api/v3/coins/bitcoin'
    );
    const result = {
      eth: {
        price: _.find(
          _.get(ethData, 'tickers', []),
          (item: any) => item.base === 'ETH' && item.target === 'USDT'
        ).last,
        price_change_24h: _.get(ethData, 'market_data.price_change_24h', 0),
      },
      btc: {
        price: _.find(
          _.get(btcData, 'tickers', []),
          (item: any) => item.base === 'BTC' && item.target === 'USDT'
        ).last,
        price_change_24h: _.get(btcData, 'market_data.price_change_24h', 0),
      },
    };
    return result;
  });

export const useGetPaymentOptions = () => {
  return useQuery([APIPath.GET_PAYMENT_OPTIONS, {}], async () => {
    const data = await getPaymentOptions();
    return data;
  });
};

export const useGetTransactionDetails = (id: string) => {
  return useQuery([APIPath.GET_TRANSACTION_DETAILS, { id }], async () => {
    const data = await getTransactionDetails(id);
    return data;
  });
};

